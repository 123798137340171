import { axios } from "@/utils/request";

// 2090103 下单
export function addOrderDevice(data) {
  return axios({
    url: '/order/addOrderDevice',
    method: 'post',
    data
  })
}

// 2090103 客户订单列表
export function getCustomerList(data) {
  return axios({
    url: '/order/getCustomerList',
    method: 'post',
    data
  })
}

// 2090106 下单（店铺限免）
export function addCouponFree(data) {
  return axios({
    url: '/order/addCouponFree',
    method: 'post',
    data
  })
}
